import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";

const Dashboard = () => {
  const { token, logout } = useUser();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchDashboardData();
    }
  }, [token, navigate]);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        "https://api.runestones.exchange/me/dashboard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch dashboard data");
      console.error(err);
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };


  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!data) {
    return <div className="">Loading...</div>;
  }

  return (
    <div className="min-h-screen p-2">
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Dashboard;